import React, { useState } from 'react';
import classNames from 'classnames';
import 'tailwindcss/tailwind.css';


const VerticalTab = ({ tabs, content }) => {
    const [activeTab, setActiveTab] = useState(0);
  
    const handleTabClick = (index) => {
      setActiveTab(index);
    };
  
    return (
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/6">
          <ul className="flex flex-col space-y-2 md:border-r rounded md:border-gray-200 md:space-y-4 dark:bg-gray-100">
            {tabs.map((tab, index) => (
              <li
                key={index}
                className={classNames(
                  'text-center cursor-pointer py-2 md:py-4 md:px-6 transition-colors duration-300',
                  {
                    'bg-green-700 text-white': activeTab === index,
                    'hover:bg-green-200': activeTab !== index,
                  },
                )}
                onClick={() => handleTabClick(index)}
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full md:w-5/6">
          <div className="p-4 md:p-6">{content[activeTab]}</div>
        </div>
      </div>
    );
  };
  
  export default VerticalTab;
  
